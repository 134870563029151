.content-box{
    display: flex;
    justify-content: space-evenly;
    background-size: cover;
    background-repeat: no-repeat;
    img{
        width: 100%;
    }
    h4 {
        font-size: 1.3rem;
       
    }

    p{
        font-size: 15px;
        display: none;
  
    }
    .text-container{
        width: 72%;
        background-color: #033b64;
        padding: 10px;
        opacity: 0.7;
        color: #ffffff;
        float: right;
        bottom: 53px;
        margin-left: 35px;
        position: absolute;

    }
}

.text-container:hover {
    height: 50vh;
   width: 70%;
   font-size: 14px;
   p{ 
       display: block;
   }
 }

 @media screen and (min-width:992px) and (max-width:1024px){
    .text-container:hover {
        width: 74%;
        height: 25vh;
   }
   .content-box{
    .text-container{
        bottom: 32px;
        margin-left: 29px;
    }
    p{
        font-size: 12px;
    }
   }
  }

  @media screen and (min-width:768px) and (max-width:991px){
    .content-box{
        .text-container{
            bottom: 32px;
            margin-left: 25px;
        }
        p{
            font-size: 11px;
        }
        h4 {
            font-size: 1.0rem;   
        }
    }
    .text-container:hover {
        height: 25vh;
        width: 78%;
    }
  }
  @media screen and (min-width:366px) and (max-width:470px){
    .content-box{
        .text-container{
            bottom: 28px;
        }
        p{
            font-size: 13px;
        }
    }
    .text-container:hover {
        height: 40vh;
    }
  }

  @media screen and (min-width:296px) and (max-width:365px){
    .content-box{
        .text-container{
            bottom: 28px;
            margin-left: 32px;
        }
        p{
            font-size: 10px;
        }
        h4 {
            font-size: 0.9rem;   
        }
    }
    .text-container:hover {
        height: 30vh;
    }
  }

  @media screen and (min-width:280px) and (max-width:296px){
    .content-box{
        .text-container{
            bottom: 28px;
            margin-left: 30px;
        }
        p{
            font-size: 9px;
        }
        h4 {
            font-size: 0.7rem;    
        }
    }
    .text-container:hover {
        height: 25vh;
    }
  }        

  @media screen and (min-width:1024px) and (max-width:1336px){
    p{
        font-size: 18px !important;
    }
  }

@media screen and (min-width: 768px){
.ourservices-offset-md-2 {
    margin-left: 1.666667% !important;
}
 
}