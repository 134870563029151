.BuilderProfile {
    width: 100%;
    background-image: url('../../../../assets/images/Bgimage.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    .overlay {
        background-color: #033b64;
        opacity: 0.7;
        padding: 150px 0px;
        color: #ffffff;
        p {
            text-align: justify;
        }
    }
    h1 {
        font-size: 55px;
    }
    .knowmore {
        padding: 15px 25px;
        background-color: #fab005;
        border: 0px;
        color: #ffffff;
        font-size: 13px;
    }
    p {
        font-size: 15px;
    }
}

.top-navbar {
    .active {
        border-bottom: 3px solid #ffa20d !important;
    }
    h4 {
        cursor: pointer;
    }
}

.Projects {
    .btn-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        text-align: right;
        text-transform: none;
        color: #ffffff;
    }

    h3 {
        color: #033b64;
    }
    h5 {
        color:#16213E;
        font-weight: 700;
        @media screen and (max-width:350px) {
            font-size: 1rem;
        }
    }

    p {
        color: #41444b;
    }

    .compyLogo {
        box-shadow: 0px 0px 5px #ddd;
    }

    .slideBtns {
        position: relative;
        top: -50%;
        width: 100%;
        button {
            padding: 15px;
            background-color: #fab005;
            border: 0px;
        }
    }

    .pdfblock {
        border: 1px dashed #d4d7dd;
        box-sizing: border-box;
        padding: 40px 40px;
        margin: 20px 0px;
    }
    .contact {
        padding: 15px;
        background-color: #ffa20d;
        border: 0px;
        color: #ffffff;
    }
    .hire {
        padding: 15px;
        background-color: #00215b;
        border: 0px;
        color: #ffffff;
    }

    ul {
        margin: 0px;
        padding: 0px;
        li {
            // border-left: 5px solid #fab005;
            margin-top: 40px;
            box-shadow: 0px 0px 5px #ddd;
            img {
                width: 100%;
                height: 100%;
                border-left: 5px solid #fab005;
            }

            h4 {
                font-weight: 700;
            }

            a {
                color: #033b64;
                text-decoration: none;
            }
        }
    }
    .view-Project-Arrow {
        width: 10px !important;
        margin-top: -2px;
        margin-left: 10px;
        border-left: none !important;
    }
}

// My Projects (Builder page)

.bg-img {
    position: relative;
    .button2 {
        position: absolute;
        bottom: 102px;
        right: 230px;
        @media screen and (min-width: 320px) and (max-width: 768px) {
            bottom: 28px;
            right: auto;
        }

        @media screen and (min-width: 769px) and (max-width: 992px) {
            right: 129px;
        }
        @media screen and (max-width: 319px) {
            position: absolute;
            bottom: 20px;
            right:auto;
            padding: 3px;
        }
    }
    .overlay {
        .container {
           // max-width: none;
        }
    }
}

.ml-auto {
    @media screen and (min-width: 768px) {
        margin-right: 3%;
    }
}
.select1{
    @media screen and (max-width:400px) {
        margin-bottom: 5px !important;
    }
}
.select2{
    @media screen and (max-width:400px) {
        margin-bottom: 3px !important;
    }
}
.viewproject{
    color:#033B64;
}


@media screen and (min-width: 320px) and (max-width: 991px) {
    .Projects{
        .btn-text{
            font-size: 13px !important;
        }
    }
    
}
