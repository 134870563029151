.ourMissinBtn{
    width: 160px;
    height: 60px;
}
.ourMissionHead{
    font-weight: 600 !important;
}
.ourmission-para{
    font-size: 18px;
    line-height: 28px;
    color: #41444B;
    font-weight: 400;
    // font-family: Poppins;

}