.FinanceBroker{
    background-image: url(../../../../assets/images/financialbrokers.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding: 22% 0px;
    position: relative;

    .bannerButtons{
        position: absolute;
        top: 90%;
        left: 10%;
    }
    button{
        gap: 10px;;
        background: #033B64;
        font-weight: 500;
        font-size: 18px;
        line-height: 36px;
        text-align: right;
        color: #FFFFFF;
        border-radius: 6px;
    }

    button:nth-child(2){
        background: #F2F2F2 !important;
        color: #033B64;
        border: 1px solid #033B64;
    }
}
.disabled-button{
    opacity: 0.5;
  
}