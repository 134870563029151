.userContact-header{
    background-color: #033B64;
    justify-content: space-between;
    p{
        font-size: 16px;
        color: #F8F8F8;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 0px;
    }
    img{
        cursor: pointer;
    }
}
.userContact-container{
    p{
        font-size: 14px;
        color: #41444B;
        font-weight: 500;
        line-height: 21px;
    }
    button{
        border-radius: 4px;
        padding: 14px, 40px, 14px, 40px;
        gap: 10px;
    }
}

.userContact-success{
    position: relative;
    justify-content: center;
    align-items: center;

}