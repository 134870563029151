.register{
    border-top: 8px solid #FFA20D;
    border-bottom: 0px solid;

    .loginhead{
        color: #FFA20D;
        font-weight: 500;
    }

    h1{
        font-size: 45px;
        color: #033B64;
        font-weight: 700;
    }

    button{
        padding: 15px 26px;
        background-color: #033B64;
        border: 0px ;
        color: #ffffff;
        font-weight: 500;
        font-size: 17px;
    }

    .signup{
        color: #FFA20D; 
        cursor: pointer;
    }
}
.my-4{
    label{
        font-weight: 400;
    }
}
.disabled-button{
    opacity: 0.5;
  
}