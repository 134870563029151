.Supplier{
    background-image: url(../../../../assets/images/rawmaterials.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding: 22% 0px;
    position: relative;
    .bannerButtons{
      position: absolute;
      right: 25%;
      bottom: 5%;
  }
  button{
    gap: 10px;
    background: #033B64;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    text-align: right;
    color: #FFFFFF;
    border-radius: 6px;
  }
  }