.Architect{
  background-image: url(../../../../assets/images/ArchitectBanner.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  padding: 22% 0px;
  position: relative;
  .bannerButtons{
    position: absolute;
    top: 70%;
    left: 10%;
}
button{
  gap: 10px;;
  background: #FFFFFF;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  text-align: right;
  color: #033B64;
  border-radius: 6px;
}
}

.card {
    width: 100%;
    height: 100%;
  }
  
  .card-one {
    border: 1px solid lightgray;
    border-top: 5px solid orange;
  }
  
  .details-part {
    color: #00215B;
    text-align: center;
  }
  
  .star-icon {
    color: orange;
  }

  .disabledCursor{
    cursor:default;
  }
  .buildersBlock{
    h4{
      font-weight: 700;
    }
    h5{
      font-weight: 600; 
    }
  }
  .architectCard{
    img{
      cursor:pointer;
    }
  }