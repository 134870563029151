.blog-container{
    .blog-container-box{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        // justify-content: space-between;
    }
    
    img{
        width: 100%;
        height: auto;
        border-radius: 24px 24px 0px 0px;
    }
    .blog-card{
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        background: #F2F2F2;
        width: 100%;
        height: 100%;
    }

    p{
        font-size: 20px;
        margin-left: 15px;
    }
    a{
        margin-left: 15px;
        margin-bottom: 10px;
        text-decoration: none;
    }
    .blog-box{
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;  
    }
   
}

@media screen and (min-width: 766px) and (max-width: 991px) {
    .blog-container{
        p{
            font-size: 18px;
            margin-left: 10px;
        }
        .real-estate{
            img{
                height: auto !important;
            }
        }
    }
}
@media screen and (min-width: 258px) and (max-width: 766px) {
    .blog-container{
        p{
            font-size: 12px;
            margin-left: 10px;
        }
        .real-estate{
            img{
                height: auto !important;
            }
        }
    }
}
@media screen and (min-width: 194px ) and (max-width: 258px) {
    .blog-container{
        p{
            font-size: 10px;
            margin-left: 10px;
        }
        .real-estate{
            img{
                height: auto !important;
            }
        }
    }
}

.blog-keyWords{
    h4{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 44px;
        line-height: 66px;
        color: #033B64;
        text-align: center;
    }

}

.blog-header{
    p{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #666666;
    }
}