/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'AH Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Regular'), url('../fonts/Poppins-Regular.ttf') format('ttf');
    }
    
    
    @font-face {
    font-family: 'AH Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Italic'), url('../fonts/Poppins-Italic.ttf') format('ttf');
    }
    
    
    @font-face {
    font-family: 'AH Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Medium'), url('../fonts/Poppins-Medium.ttf') format('ttf');
    }

    @font-face {
        font-family: 'AH Bold';
        font-style: normal;
        font-weight: bold;
        src:local('Poppins Bold'), url('../fonts/Poppins-Bold.ttf') format('ttf');;
    }    
    
    @font-face {
    font-family: 'AH Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('AH Sans Medium Italic'), url('../fonts/Poppins-MediumItalic.ttf') format('ttf');
    }    
    
    @font-face {
    font-family: 'AH Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('AH Bold Italic'), url('../fonts/Poppins-BoldItalic.ttf') format('ttf');
    }