.BuilderContentHeader{
    background: #033B64;
    margin-top: 5%;
    p:nth-child(1){
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        color: #FFFFFF;
        margin-bottom: 0px;
    }
    p{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 28px;
        color: #FFFFFF;
    }
    a{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 28px;
        color: #FFFFFF;
    }
}
.BuilderContent{
    font-family: 'Poppins';
    font-style: normal;
    h4{
        font-weight: 700;
        font-size: 33px;
        line-height: 45px;
        color: #033B64;
    }
    p{
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.5px;
        color: #41444B;
    }
    li{
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        align-items: center;
        letter-spacing: 0.5px;
        color: #41444B;
        background: #F2F2F2;
        border-radius: 8px;
        margin-bottom: 10px;
        padding: 10px 15px
    }
}
.builderList{
    text-align: center;
}

.builderContentcard{
    box-shadow: 0 0 10px #ddd;
    width: 100%;
    border-radius: unset;
    margin-bottom: 10px;
}