@media screen and (max-width:344px) {
    .butcancel{
        margin-top:5px;

    }
}
.buttoncontainer{
        left:-17%;
}
.butupdate{
    border: none;
    height: 50px;
    width: 130px;
    padding: 5px;
    color:#fff;
    border:none;
    background-color:  #fab005;
}
.butcancel{
    border:none;
    width:75px;
    padding: 10px;

}
.updatecontact {
    background-color: #fab005;
    color: #fff;
    border:none;
}
.save-but{
    border:none;
    width:75px;
    padding: 10px;
}
@media screen and (max-width:319px) {
    .projectnameup{
        width:60%;
        font-size: 1rem;
    }
    .adLine1Up{
        width:70%;
        font-size: small;
    }
    .adLine2Up{
        width:70%;
        font-size: small;
    }
    .citydropdown{
        width:65%;
        font-size: small;
    }
    .statedropdown{
        width:55%;
        font-size: small;
    }
    .country{
        width:70%;
        font-size: small;
    }
    .pincode{
        width:70%;
        font-size: small;
    }
    .phoneNum{
        width:70%;
        font-size: small;
    }
    .aboutproject{
        width:70%;
        font-size: small;
    }
    .landsizeupdate{
        width:70% ;
        font-size: small;
    }
    .cost{
        width:70%;
        font-size: small;
    }
    .no-border{
        width:70%;
        font-size: small;
    }
    
}
.button7{
    background-color:#FFA20D;
    height: 60px;
    bottom: 128px;
    font-size: 17px;
    @media screen and (max-width:768px) {
    bottom: 25px;
}
}