
.newmap-sctn {
    height: 40vh;
    justify-content: center;
    display: flex;
    img {
        width: 4%;
    }
}
.contactus{
    display: flex;
    flex-direction: column;
.message{
    font-weight: 700;
    color: #16213E;
 

}
}
.button-send{
    background-color: #033B64;
    border: none;
        width: 200px;
        height: 52px;
        color:white;
}
.new-address{
    height: 52%;
    @media screen and(min-width:768px) {
        max-width:85% ;
        // height: 49%;
    }
    @media screen and(min-width:291px) and(max-width:767px) {
        max-width:70% ;
        // height: 45%;
        margin-top: 20px;
    }
    @media screen and(max-width:290px) {
        max-width:80% ;
        height: 60%;
        margin-top: 10px;
    }
    p{
        color:#00215B;
        @media screen and(min-width:768px) {
           font-size:17px;
        }
    }
    h5{
   color:#41444B;
    }
}
.add-text-container{
    padding: 10px 30px 10px 30px ;

}

.maildots{
    position: absolute;
}
.dot-image{
    margin-top:-73px;

}
.address-textbox{
    @media screen and (min-width:300px) and (max-width:340px) {
        h5{
            font-size: 1.1rem;

        }
        p{
            font-size: 14px;
        }
    }
    @media screen and (max-width:299px) {
        h5{
            font-size: 1rem;

        }
        p{
            font-size: 13px;
        }
    }
}
.contactus-disble{
    opacity: 0.7;
}
