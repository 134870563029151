.findProjets {
    width: 100%;
    // background-image: url("../../../assets/images/Bgimage.jpg");
    background-repeat: no-repeat;
    background-size: cover;


    h1 {
        // font-size: 100px;
        font-size: 55px;
        // font-weight: 600 !important;
    }

    .knowmore {
        padding: 15px 25px;
        background-color: #fab005;
        border: 0px;
        color: #ffffff;
        font-size: 13px;
    }

    p {
        font-size: 15px;
    }
}

.findProjets-banner {
    width: 100%;
    background-image: url("../../../../assets/images/findProjectBanner.png");
    background-repeat: no-repeat;
    background-size: auto;
    margin-top: 6%;
    width: 100%;
}

.overlay-banner {
    // background-color: #033b64;
    opacity: 0.7;
    padding: 10% 0px;
    color: #ffffff;

    p {
        text-align: justify;
    }

}

.projectsList {
    span {
        color: #033b64;
        font-weight: 700;
        font-size: 18px;
    }

    ul {
        margin: 0px;
        padding: 0px;

        li {
            // border-left: 5px solid #fab005;
            margin-top: 40px;

            img {
                width: 100%;
                height: 100%;
            }

            h4 {
                font-weight: 700;

            }

            a {
                color: #033b64;
                text-decoration: none;
            }
        }
    }
}

.new {

    // @media screen and(min-width:339px) and (max-width:577px) {
    //     margin-right: auto !important;
    // }
    @media screen and (min-width:768px) {
        margin-right: 3%;
    }
}

.row1 {
    @media screen and (min-width:307px) and (max-width:767px) {
        margin-right: 0%;
    }
}

.col-md-7 {
    .projectname {

        @media screen and (min-width:250px) and (max-width:400px) {
            font-size: 1.1rem;

        }

        @media screen and (max-width:249px) {
            font-size: 0.9rem;

        }
    }
}

.projectname {
    color: #033b64;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 0.5px 4px !important;
}

.find-dumy-Projects {
    .btn-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        text-align: right;
        text-transform: none;
        color: #ffffff;
    }

    h3 {
        color: #033b64;
    }

    h5 {
        color: #16213E;
        font-weight: 700;

        @media screen and (max-width:350px) {
            font-size: 1rem;
        }
    }

    p {
        color: #41444b;
        margin-bottom: 0px;
    }

    .compyLogo {
        box-shadow: 0px 0px 5px #ddd;
    }

    .slideBtns {
        position: relative;
        top: -50%;
        width: 100%;

        button {
            padding: 15px;
            background-color: #fab005;
            border: 0px;
        }
    }

    .pdfblock {
        border: 1px dashed #d4d7dd;
        box-sizing: border-box;
        padding: 40px 40px;
        margin: 20px 0px;
    }

    .contact {
        padding: 15px;
        background-color: #ffa20d;
        border: 0px;
        color: #ffffff;
    }

    .hire {
        padding: 15px;
        background-color: #00215b;
        border: 0px;
        color: #ffffff;
    }

    ul {
        margin: 0px;
        padding: 0px;

        li {
            // border-left: 5px solid #fab005;
            margin-top: 20px;
            box-shadow: 0px 0px 5px #ddd;

            img {
                width: 100%;
                height: 100%;
                border-left: 5px solid #fab005;
            }

            h4 {
                font-weight: 700;
            }

            a {
                color: #033b64;
                text-decoration: none;
            }
        }
    }

    .service-provider-container {
        color: #41444B;

        p:nth-child(1) {
            font-size: 16px;
            font-weight: 700;

        }

        p:nth-child(2) {
            font-size: 14px;
            font-weight: 400;

        }

        .rating-text {
            p {
                font-size: 16px;
                font-weight: 400;
                color: #FFA20D;
            }

        }
    }

    .customer-service-container {
        color: #41444B;
        font-size: 14px;
        font-weight: 500;

        p:nth-child(1) {
            font-size: 16px;
            font-weight: 700;

        }

        .headings {
            font-size: 14px;
            font-weight: 500;
            color: #033B64;
        }
        .service-rating {
            color: #41444B;

            p:nth-child(1) {
                font-size: 14px;
                font-weight: 500;

            }

            p:nth-child(2) {
                font-size: 16px;
                font-weight: 400;
                color: #FFA20D;
            }

            margin-right: 20px;
        }
    }

}