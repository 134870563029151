.query-container{
    background: #033B64;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}
.query-container-box{
    flex-direction: column;
    font-family: 'Poppins';
    font-style: normal;
   
    p{
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: #FFFFFF;
    }
    input{
        background: #FFFFFF;
        border: 1px solid #D4D7DD;
    }
    ::placeholder{
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: rgba(65, 68, 75, 0.6);
        padding-left: 10px;
    }
    button{
        background: #FFFFFF;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #033B64;
        border: none;
    }
}
.support{
    font-family: 'Poppins';
    font-style: normal;
    h3{
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #033B64;
    }
    p nth:child(1){
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #41444B;
        text-align: left;
    }
}