// .container{
//   text-align: center !important;
// }

ul{
  list-style: none;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

.main-container{
  height:600px;
}