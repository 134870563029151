
.aboutArchitectProfile {
    h3 {
        color: #033b64;
    }
    h5 {
        color: #033b64;
    }

    p {
        color: #41444b;
        overflow-wrap: break-word;
    }

    .compyLogo {
        box-shadow: 0px 0px 5px #ddd;
    }

    .slideBtns {
        position: relative;
        top: -50%;
        width: 100%;
        button {
            padding: 15px;
            background-color: #fab005;
            border: 0px;
        }
    }
    .contact {
        padding: 15px;
        background-color: #ffa20d;
        border: 0px;
        color: #ffffff;
    }
   

    ul {
        margin: 0px;
        padding: 0px;
        li {
            border-left: 5px solid #fab005;
            margin-top: 40px;
            box-shadow: 0px 0px 5px #ddd;
            img {
                width: 100%;
                height: 100%;
            }

            h4 {
                font-weight: 700;
            }

            a {
                color: #033b64;
                text-decoration: none;
            }
        }
    }
}

.view-pro-arrow{
    color: #00215B;
    height:12px !important;
    width:10px !important;
}
.other-image{
    height: 100px;
    @media screen and (min-width:400px) and (max-width:767px) {
        height: 180px;
    }
    @media screen and (max-width:399px) {
        height: 150px;
    }
}