.Builders {
    background: #F4F4F4;
    padding: 50px 0px;
    button{
        gap: 10px;;
        background: #033B64;
        font-weight: 500;
        font-size: 18px;
        line-height: 36px;
        text-align: right;
        color: #FFFFFF;
    }
    h1 {
        font-size: 55px;
    }
    p {
        font-size: 15px;
    }
}

.buildersBlock {
    .locationList {
        margin: 20px 0px;
        padding-left: 0;
        li {
            color: #767676;
            padding: 15px 0px;
            border-bottom: 1px solid #f0f0f0;
        }
    }
    .search-bar-section{
        display: flex;
        flex-direction: row;

        img{
            position: relative;
            right: 34px;
            margin-right: auto;
            cursor: pointer;
            width: 4%;
        }
    }

    .RatingList {
        padding-left: 0;
        li {
            color: #767676;
            padding: 15px 0px;
            border-bottom: 1px solid #f0f0f0;
            font-size: 13px;
            align-items: center;
            display: flex;
            .rating {
             font-size: 16px;
            }
        }
    }

    .builderscard {
        li {
            list-style: none;
            color: #033b64;
            text-align: center;
            padding: 8px !important;
            .buildercard {
                box-shadow: 0 0 10px #ddd;
                width: 100%;
                border-top: 4px solid #ffa20d;
                box-shadow: 1px 1px 1px 0.5 #ffa20d;
                margin-bottom: 10px;
                img {
                    width: 100%;
                }
            }
            h5 {
                font-size: 16px;
                font-weight: 700;
            }
            p {
                text-align: center;
                font-size: 12px;
            }
            .rating {
                color: #033b64;
                font-size: 13px;
                margin: 0px;
            }
            .buildername {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                text-align: center;
            }
            .location {
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 15px;
                text-align: center;
            }
        }
    }
}

// search icon
.input-search{
    max-width: 150%;
}
.bg-img{
    position: relative;
       
    }
    .input-cntr{
        position: relative;
        margin-left: auto;
        width: 33%;
        .search-icon{
            background-image: url(../../../../assets/images/search.svg);
            background-repeat: no-repeat;
            background-position: 10px 12px;
        }
        input{
            background: #FFFFFF;
            border: 1px solid #D4D7DD;
            text-indent:17px;
        }

        ::placeholder{
            padding-left: 10%;
        }

        @media  (max-width:319px) {
            right: auto;
        }
        .search-bar{
            @media  (max-width:319px) {
               width:80%;
               margin-left: 2%;
            }
        }
        img{
            cursor: pointer;
            @media screen and (max-width:319px)  {
                right:20%
            }
        }
    }
    .close-search {
        margin-left: -34px !important;
        width: 5%;
        margin-right: 5px;
    }


    
// for search icon
@media screen and (min-width: 567px) and (max-width: 768px){
    .bg-img{
        .input-cntr{
            right: unset;
        }
    }
}
@media screen and (min-width: 320px) and (max-width: 575px){
    .bg-img{
        .input-cntr{
            right: unset;
        }
    }
}



// For Mobile devices
@media screen and (max-width: 768px) {
    .buildersBlock{
        .RatingList{
            padding-left: 0;
        }
        .builderscard{
            padding-left: 0; 
        }
        .w-100{
            width:80%!important;
        }
    }
}

// For Large devices
@media screen and (min-width: 768px) {
    .buildersBlock{
        .btn.btn-info{
            display: none;
        }
        .locationList{
            display: block;
        }
        
    }
}








