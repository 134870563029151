.Townplanner-SecondFold{
    background: rgb(242, 242, 242);
    border-radius: 24px;
    flex-direction: column;
    display: flex;
    align-items: center;

    img{
        width: 50%;
    }
}