.projectInfo{
    h3{
    color:  #00215B;
    font-weight: 600 ;
    // margin-top: 0.8rem;
}
}
.addresshead{
    margin-top:0.7rem;
}

.btn-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        text-align: right;
        text-transform: none;
        color: #ffffff;
}
// .pending-btn {
//     padding: 2px 1px 2px 1px !important;
// }