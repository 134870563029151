.bg-img {
    width: 100%;
    background-image: url('../../assets/images/Bgimage.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.overlay {
    background-color: #033b64;
    opacity: 0.7;
    padding: 90px 0px;
    color: #ffffff;
    p {
        // text-align: justify;
    }
}
.breadcrumbs {
    color: #ffffff;
    font-size: 55px;
}

.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
    
.circle {
    padding: 7px;
    border:1px solid #ffa20d;    
    height:30px;
    width:30px;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
}

.cancel-btn {
    background-color: #ff4949;
    padding: 2px 10px 2px 10px;
    color: white
}
.pending-btn {
    background-color: #2f80ed;
    padding: 2px 10px 2px 10px;
    color: white
    // height: 26px;
    // left: 89.65%;
    // right: 0.76%;
    // top: calc(50% - 26px/2 - 62px);
    // font-family: Poppins;
    // font-style: normal;
    // font-weight: normal;
    // font-size: 16px;
    // line-height: 26px;
    // text-align: right;
    // color: #FFFFFF;
}
.completed-btn {
    background-color: #00a824;
    padding: 2px 10px 2px 10px;
    color: white
}

.contact {
    padding: 15px;
    background-color: #ffa20d;
    border: 0px;
    color: #ffffff;
}
.hire {
    padding: 15px;
    background-color: #00215b;
    border: 0px;
    color: #ffffff;
}
.file-name{
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
}
.icon-button {
    padding: 15px;
    background-color: #fab005;
    border: 0px;
}
.icon-button-disabled {
    padding: 15px;
    background-color: #fab005;
    border: 0px;
    cursor: default;
    opacity: 0.7;
}
.disabled-arrow{
    .carousel-control-next, .carousel-control-prev{
        cursor: default !important;
        opacity: 0.2 !important;
    }
    
}
.bottom-buffer { margin-bottom:20px; }
.left-buffer { margin-left: 10px; }
.top-buffer { margin-top: 34px; }
.right-end { float: right }
.proceed {
    display: inline-block;
    height: 100%;
    width:"12px";
}
.no-border{
    border: none
}
.left-tab { margin-left: 40px; }
.modal-header{
    // padding: 50px 0px;
    background-color: #FFA20D;
    color: #ffffff;
}
.modal-body {
    background-color: #fff;
}
.ok-button{
    padding: 5px 10px 5px 10px;
    background-color: #00215b;
    border: 0px;
    color: #ffffff;
}
.warning{
    color: #FF4949;
    ;
}
.light-content{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #767676;
    overflow-wrap: break-word;
}

.img-wrap {
    position: relative;
}
.upload-container{
    display: flex;
    justify-content: space-between;
    .img-wrap{
        border: 1px dashed;
        padding: 8px 10px;
        font-size: 20px;
        border-radius: 10px;
    }
    .builderproject-img-wrap{
        background: #FFFFFF;
        border: 1px dashed #D4D7DD;
        box-sizing: border-box;
    }
    .left-tab{
        margin-left: 0;
        margin-bottom: 0;
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 15px;
        color: #41444B;
        padding: 40px 10px 40px 10px;
    }
}
.builderproject-img-wrap .img-wrap .close {
    position: absolute;
    top: 0px;
    right: 2px;
    z-index: 100;
    opacity: 0.8;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 25%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

textarea{
  border:1px solid #999999;
  width:100%;
  margin:5px 0;
  padding:3px;
}

#container {
    border: 2px solid Black;
    padding: 15px 15px 15px 15px;
    margin: 20px 20px 20px 20px;
    position: relative;
    width:350px;
  }
  
.x-icon {
      position: absolute;
      right: -157px;
  }

.builder-logo{
    width:auto;
    height: 350px;
    box-shadow: 0px 0px 5px 3px lightgrey;
    margin-top: 10px;
}

.content-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

select {
    text-align-last:center; 
}

.buildercard{
    border: 1px solid gray;
    width: 120px;
    padding: 15px 10px;
    text-align: center;
    border-radius: 10px;
    .MuiSvgIcon-root{
        width: 40px;
        height: 70px;
    }
}

.profilecard{
    border: 1px solid gray;
    width: 350px;
    padding: 15px 10px;
    text-align: center;
    border-radius: 10px;
    .MuiSvgIcon-root{
        width: 40px;
        height: 70px;
    }
}


.static-content{
    width: 50%;
    margin: auto;
    font-size: 17px;
}
// For Mobiles Find Projects (Builder page)
@media screen and (min-width: 320px) and (max-width: 762px){
    .Projects{
        .my-5.filterby-section{
            .row.align-items-center {
                font-size: 13px;
                margin: 0 5px;
            }
        }
    }
}
// My Projects (Builder page)
@media screen and (min-width: 320px) and (max-width: 762px){
    .Projects{
        .row.align-items-center{
            h4{
                margin-left: 7px;
                margin-bottom: 18px;
            }
            .ml-auto{
                text-align: center;
            }
            .px-3.py-1{
                margin-bottom: 15px;
            }
        }
    }
}

// NewProject(Customer Page)
.Projects{
    .address{
        .px-3{
            margin-bottom: 10px;
        }
    }
    .city{
        select:first-child{
            margin-bottom: 10px;
        }
    }
}

// profile button(customer page)
.bg-img{
    position: relative;
    margin-top: 5%;
    .col-md{         
        .button3{
            img{
                margin-bottom: 3px;
            }
            position: absolute;
            right: 240px;
            height: 60px;
            bottom: 100px;
            font-size: 18px;
            &.save-btn{
                right: 320px;
                width: 80px;
            }
            &.cancel-btn{
                right: 210px;
            }
        }
    }
}
@media screen and (min-width: 280px) and (max-width: 320px){
    .bg-img{
        .col-md{
            .button3{
                right: 240px;
                height: 60px;
                bottom: 29px;
                font-size: 18px;
    
                &.cancel-btn{
                    right: 65px;
                }
                &.save-btn{
                    right: 167px;
                    //width: 80px;
                }
            }
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 768px){
    .bg-img{
        .col-md{
            .button3{
                right: auto;
                left: 15px;
                bottom: 28px;
                &.cancel-btn{
                    left:112px;
                }
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .bg-img{
        .col-md{
            .button3{               
                &.cancel-btn{
                    right: 80px;
                }
                &.save-btn{
                    right: 190px;
                }
            }
        }
    }
}
// social media (profile name) (customer page)
.scrollableContent::-webkit-scrollbar {
    display: none;
}
// .scrollableContent {
//     -ms-overflow-style: none;  /* IE and Edge */
//     scrollbar-width: none;  /* Firefox */
//   }
.scrollableContent{
    max-height: calc(100vh - -25px);
    overflow-y: scroll;
}
.aboutProject{
  
    .projectInfo{
        margin-top: -7px;
    }
}
// Scrollbar popup(builderpage)
.aboutProject.scrollbar-section{
    .editbtn-section{
        .button2.contact.save-btn{
            width: 70px;
        }
        .button2.contact.cancel-btn{
            margin-left: 10px;
        }

    }
}

// popup section in edit portfolio
.aboutProject.scrollbar-section{
    .textheader-section{
        display: flex;
        justify-content: space-between;
        h4{
            margin-top: 10px;
        }
    }
    .editbtn-section{
        left: 235px;
    }
}



// Image upload section portfolio
@media screen and (min-width: 320px) and (max-width: 500px){
    .scrollbar-section{
        .upload-container{
            display: block;
        }
    }
}
@media screen and (min-width: 769px) and (max-width: 1100px){
    .scrollbar-section{
        .upload-container{
            display: flex;
        }
    }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.link-text{
    color: white;
    background-color: transparent;
    text-decoration: none;
  }
  
  .disbledSaveButton{
    opacity: 0.7;
}

.empty-Message-Container{
        position: relative;
        top: 15px;  
}
.empty-Message-Content{
        display: flex;
        flex-direction: column;
        width: 71%;

        p{
            font-style: normal;
            font-weight: normal;
            font-size: 22px;
            line-height: 33px;
            text-align: center;
            color: #41444B;
        }
        img{
            width: 100%;
            height: 15vh;
        }
    }

.empty-Findproject-Container{
    position: relative;
    top: 50px;  
}
.empty-Findproject-Content{
    display: flex;
    flex-direction: column;
    width: 41%;

    p{
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 33px;
        text-align: center;
        color: #41444B;
    }
    img{
        width: 100%;
        height: 11vh;
    }
    img:nth-child(2){
        width: 44%;
        height: 6vh;
        margin-left: 131px;
        margin-top: -39px;
    }
}


@media screen and (min-width: 996px) and (max-width: 1324px) {
    .profilecard{
        border: 1px solid gray;
        width: 280px;
        padding: 15px 10px;
        text-align: center;
        border-radius: 10px;
        .MuiSvgIcon-root{
            width: 40px;
            height: 70px;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 995px) {
    .profilecard {
        width: 298px;
        padding: 15px 10px;

    }
    .profileInfo{
        margin-left: 36%;
        width: 100%;
    }
    .empty-Message-Content{
        width: auto;
        p{
            font-size: 19px;
            line-height: 29px;

        }
    }
    .empty-Findproject-Content{
            width: 47%;
        p{
            font-size: 15px;
            line-height: 29px;

        }
    }
}

@media screen and (min-width: 407px) and (max-width: 767px) {
    .profileInfo{
        margin-top: 20px !important;
    }
    .empty-Message-Content{
        width: 100%;
        p{
            font-size: 19px;
            line-height: 29px;

        }
    }
    .empty-Message-Container{
        top: 32px;  
    }
    .empty-Findproject-Content{
        width: auto;
    }

}

@media screen and (min-width: 320px) and (max-width: 407px) {
    .profileInfo{
        margin-top: 20px !important;
    }

}
@media screen and (min-width: 315px) and (max-width: 390px) {
    .profilecard {
        width: 280px;
      
    }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
    .profilecard {
        width: 249px;    
    }
    .profileInfo{
        margin-top: 20px !important;
    }
}

@media screen and (min-width: 996px) and (max-width: 1125px) {
    .x-icon {
        right: -122px;
        bottom: 301px;
        position: relative;
    }   
    .empty-Message-Content{
        p{
            font-size: 18px;
            line-height: 29px;

        }
    }
    .empty-Findproject-Content{
       
        p{
            font-size: 18px;
            line-height: 29px;

        }
    }
    
}

@media screen and (min-width: 391px) and (max-width: 766px) {
    .x-icon {
        right: -154px;
        bottom: 301px;
        position: relative;
    }   

}

@media screen and (min-width: 351px) and (max-width: 390px) {
    .x-icon {
        right: -120px;
        bottom: 301px;
        position: relative;
    }   

}

@media screen and (min-width: 280px) and (max-width: 350px) {
    .x-icon {
        right: -101px;
        bottom: 301px;
        position: relative;
    }   

}
@media screen and (min-width: 280px) and (max-width: 406px) {
    .empty-Message-Content{
        width: 100%;
        p{
            font-size: 14px;
            line-height: 21px;

        }
    }
    .empty-Message-Container{
        top: 32px;  
    }
    .empty-Findproject-Content{
        width: auto;
        p{
            font-size: 14px !important;
            line-height: 24px;
        }
    }

}
.material-list{
    span{
        cursor: pointer;
    }
    img{
        cursor: pointer;
    }
}
.apply-loan-modal{
    img {
        cursor: pointer;
    }
    p{
        margin-bottom: 0px;
    }
    input{
        margin-bottom: 10px;
    }
}
.apply-loan-container{
    img{
        margin-bottom: 10px;
        cursor: auto;
    }
}
.cursor{
    cursor: pointer;
}

.scrollablematerial{
    overflow-y: scroll;
    max-height: calc(100vh - 70vh);
}

.scrollablematerial::-webkit-scrollbar {
    display: none;
}

.link-span{
    color: #007bff;
    cursor: pointer;
    &:hover{
        border-bottom: 1px solid #0056b3;
        color: #0056b3 !important;
    }
}

