@media screen and (max-width:319px) {
    .button4{
        bottom:36px !important;
        font-size: 15px !important;
     //    padding:10px !important;
    }
    .button4.save-btn1{
         width: 55px !important;
      right:auto !important;
      padding: 8px !important;
     }
     .button4.cancel-btn1 {
         width: 69px ;
         left: 85px ;
         padding: 8px ;
     }
 }
 @media screen and (min-width: 769px) and (max-width: 992px){
 .button4.save-btn1 {
     right: 200px;
 }
 .button4.cancel-btn1{
     right: 75px;
 }
 }
 @media screen and (min-width: 320px) and (max-width: 768px)
 {
     .button4.save-btn1 {
         right: auto;
     }
 .bg-img .col-md .button4.cancel-btn1 {
     left: 120px;
 }
 }
 @media screen and (min-width: 993px) and  (max-width: 1441px) {
     .bg-img .col-md .button4.save-btn1 {
         right: 350px;
     }
     .bg-img .col-md .button4.cancel-btn1{
         right: 220px;
     }
     }
     @media screen and (min-width: 1442px)  {
         .bg-img .col-md .button4.save-btn1 {
             right: 35%;
         }
         .bg-img .col-md .button4.cancel-btn1{
             right: 26%;
         }
         }
         .button4{
          background-color:#FFA20D;
          position: absolute;
          right: 333px;
          height: 60px;
          bottom: 128px;
          font-size: 18px;
          @media screen and (max-width:768px) {
 
         bottom: 25px;
     }
 }

 @media screen and (min-width:260px) and (max-width:767px){
    .builder-project{
           input{
                width: 88% !important;
           }
           textarea{
            width: 88% !important;
           } 
    }
}


@media screen and (min-width:992px) and (max-width:1115px){
    .upload-container{
        .left-tab{
            font-size: 10px;
        }
    }

}
@media screen and (min-width:768px) and (max-width:991px){
    .upload-container{
       
        .left-tab{
            font-size: 10px;
            line-height: 12px;
            padding: 29px 5px 29px 5px;
           
           #Upload {
                width: 15%;
            }
        }
        .builderproject-img-wrap{
            width: 20%;
            // height: 20%;
        } 
        

    }
}
@media screen and (min-width:280px) and (max-width:768px){
    .upload-container{
       
        .left-tab{
            font-size: 9px;
            line-height: 12px;
            padding: 29px 5px 29px 5px;
           
         #Upload {
                width: 15%;
            }
        }
        .builderproject-img-wrap{
            width: 20%;
            height: 20%;
        } 
        

    }

}