.bank-managed{
    margin-left: 3%;
    margin-top: 6%;
    width: 85%;
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(0, 33, 91, 0.135681);
    h6{
        height: 26px;
        left: 31.61%;
        right: 53.91%;
        top: calc(50% - 26px/2 + 281.5px);
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        color: #16213E;
    }
}


.grid{
    max-width: 50% !important;
    flex-basis: 50% !important;
}

.dropdown-heading{
    margin-top: 10%;
    text-align: center;
}

.multiselect{
    margin-top: 10%;
    margin-left: 10%;
}

.optionContainer{
    height: 250px;
}

.cross-icon{
    margin-right: 1rem;
    cursor: pointer;
    
}

.city-name-1{
    line-height: 30px;
    font-size: 24px;
    color: #00215B;
}

.error-message{
    width: 30%;
    p{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 5px;
        display: flex;
    }

   

}
.invalid {
        color: rgba(255, 73, 73, 1);
        .invalid-icon{
            background: url(../../../../assets/images/redCross.svg) no-repeat center center;
            width: 4%;
            background-repeat: no-repeat;
            border: none;
            margin: 2px;
            background-size: contain;
        }
        
}
.valid {
        color: rgba(0, 168, 36, 1);
        .valid-icon{
            background-image: url(../../../../assets/images/greenTik.svg);
            width: 4%;
            height: auto;
            background-repeat: no-repeat;
            border: none;
            margin: 5px 6px 1px 1px;
        }
}
    



