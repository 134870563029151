.portfolio_size > img{
    width:250px;
    height:180px;
   
    margin-top:15px;
}
.client_img > img{
    height:310px;
    width:230px;
    @media screen and (min-width:992px) and (max-width:1199px) {
        width:290px;
    }
    @media screen and (min-width:1200px)  {
        width:335px;
    }
}
.portfolio_size {
    display:flex;
 
}
.builder_text{
    margin-top:-5px;
}
.projects_size > img{
    width:182px;
    height:180;
   
    border-left:5px solid orange;
}
.review_size > img{
    width:175px;
    height:180px;
    border-left:5px solid orange;
}
.project-slider{
   
    border:1px solid whitesmoke;
    box-shadow:1px 1px 1px 1px whitesmoke;
   
   
}
.review-slider{
    width:700px;
    border:1px solid whitesmoke;
    box-shadow:1px 1px 1px 1px whitesmoke;
    height:162px

}
.review-slider_highlight{
    background-color: orange;
    width:5px;
    height:187px;

}
.project-slider_highlight_review{
    background-color: orange;
    width:5px;
    height:180px;
}
.heading_text{
    color:#00215B;
    font-weight: bold;
    letter-spacing: 1px;
    font-size:30px;
}
.overlay_text{
    color:#00215B;
    font-weight: bold;
    letter-spacing: 0.2px;
    font-size:25px;
}
.para-text{
    color:#00215B;
    font-weight: bold;
    font-size:15px;
}
.description_text{
    font-size:18px;
    letter-spacing: 1px;
}
.project_description_text{
    font-size:18px;
    letter-spacing: 1px;
    margin-top:-18px;
}
.social_icon > img{
    border:1px solid orange;
    border-radius:50%;
    padding:12px;
    margin-left:10px;
    width:40px;
    height:40px;
  
}
.star_color > i{
  color:orange
}
.office_buildings{
    margin-top: 1rem;
    @media screen and (min-width:768px) and (max-width:991px) {
      min-width:100px;
      max-width:150px ;
    
    }
    @media screen and (min-width:992px)  {
        max-width:210px ;
    }
  }
  .projectImages{
      @media screen and(min-width:768px) {
        width: 165px !important; 
      }
      
  }
  .empty-Findproject-Content2{
    display: flex;
    flex-direction: column;
      @media screen and (min-width:768px) {
          width:100%
      }
      @media screen and (max-width:767px) {
        width:auto;
    }
    img{
            width: 100%;
            height: 11vh;
        
    }
  }
  .button8{
    background-color:#FFA20D;
    height: 60px;
    bottom: 128px;
    font-size: 17px;
    @media screen and (min-width:768px) and (max-width:991px) {
    bottom: 30px;
}
@media screen and (max-width:250px) {
    font-size: 13px;
}

@media screen and (min-width:992px) {
    bottom: 35px;
    margin-left: 5rem !important;
}
}
.addproject-button{
    width:17rem;
    min-height:280px;
    border-radius: 5px;
    border-width: 2px;
    border:1px solid rgba(0,0,0,.125);
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width:310px) and (max-width:370px) {
        width:90%;
    }
    @media screen and (min-width:371px) and (max-width:414px) {
        width:94%;
    }
    @media screen and (min-width:768px) and (max-width:991px) {
        width:21rem;
    }
    @media screen and (min-width:992px) and (max-width:1199px) {
        width:19rem;
    }
    img{
        margin-bottom: 0.5rem;
    }
    h6{
        color:#41444B;
        margin-left: 0.5rem;
        font-style:italic;

    }
}
.delete-img{
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}
.social-Links{
    @media screen and (max-width:399px) {
        margin-top: -20%;
    }
    @media screen and (min-width:400px) and (max-width:991px) {
        margin-top: -15%;
    }
    @media screen and (min-width:992px)  and (max-width:1199px) {
        margin-top: -12%;
    }
    
    @media screen and (min-width:1200px) {
        margin-top: -10%;
    }
}
.edit-architectname{
    @media screen and (max-width:767px) {
        margin-top: 4%;
    }
}
.update-contact{
    background-color: #fab005;
    color: #fff;
    border:none;
    width:80px;
}
.but-cancel{
    border: none;
    width: 80px;
    padding: 10px;
    @media screen and (max-width:310px) {
        margin-top:10px ;
    }
   
}
.edit-archbut{
    @media  screen and (min-width:768px){
       position: absolute;
       left:17%; 
    }
}
.buttons-container{
    @media screen and (max-width:767px) {
        margin-left:-3rem ;
    }
 
}
.portfolio-image{
    width:17rem;
    @media screen and (min-width:310px) and (max-width:370px) {
        width:90%;
    }
    @media screen and (min-width:371px) and (max-width:414px) {
        width:94%;
    }
    @media screen and (min-width:768px) and (max-width:991px) {
        width:21rem;
    }
    @media screen and (min-width:992px) and (max-width:1199px) {
        width:19rem;
    }
   
}
.text-margin{
    @media screen and(min-width:768px) {
       margin-left: 3rem;
    }
    @media screen and(max-width:767px) {
        margin-left: 1rem;
     }
}
.viewp-font{
    font-size: 0.8rem;
}