.login{
    border-top: 8px solid #FFA20D;
    border-bottom: 0px solid;

    .loginhead{
        color: #FFA20D;
        font-weight: 500;
    }

    h1{
        font-size: 45px;
        color: #033B64;
        font-weight: 700;
    }

    button{
        padding: 15px 29px;
        background-color: #033B64;
        border: 0px ;
        color: #ffffff;
        font-size: 17px;
    }

    .signup{
        color: #FFA20D; 
        cursor: pointer;
    }
}
.button{
    box-shadow: none;
    border-radius: 0;
    background-color: #033B64;
    margin-left: 8px;
}

.d-flex{
    .arrow1,.arrow2{
        height: 8px;
    }
}
