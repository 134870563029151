.sub-image{
    @media screen and (max-width:767px) {
        height: 125px;
    }
}
.buildingImage{
    @media screen and (max-width:399px) {
        height: 305px;
    }  
    @media screen and (min-width:400px) and (max-width:767px) {
        height: 340px;
    } 
}
.newbtn-pending{
    width:94px !important;
    height:28px !important;
    font-size: 13px;
    border-radius: 3px;
}