@import './colors.scss';
.blue-btn {
    color: $white;
    background-color:$vivid-blue-light;
}

.searchinput{
    width: 70%;
    padding: 5px;
    background-color: $white;
    border: 0px;
    -webkit-appearance: none;
    box-shadow: none !important; 
    
}
.serch_btn{
    position: relative;
    left: -50px;
}