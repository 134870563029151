.quote-container{
    padding: 30px;
    font-style: normal;
    button{
        background: #033B64;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: #FFFFFF;
        width: 45%;
        height: 6vh;
        border: none;
        img{
            height: 36%;
            margin-bottom: 2px;
            width: 9%;
        }
    }
}


.quote-header{
    display: flex;
    justify-content: space-between;
    // margin-top: 25px;
    p{
        font-weight: 600;
        font-size: 34px;
        line-height: 38px;
        color: #00215B;
        margin-bottom: 0px;     
    }
    img{
        margin-bottom: 12px;
        cursor: pointer;
    }
}

.quote-content{
    p{
        font-style: normal;
        font-weight: normal;
        color: #41444B;
        font-size: 14px;
        line-height: 15px;
    }
    input{
        background: #FFFFFF;
        border: 1px solid #D4D7DD;
        box-sizing: border-box;
        width: 39%;
        margin-right: 10px;
        margin-bottom: 10px;
        height: 6vh;
        margin-left: 10px;
    }
    select{
        background: #FFFFFF;
        border: 1px solid #D4D7DD;
        box-sizing: border-box;
        width: 59%;
        height: 6vh;
        font-style: italic;
    }
    
   span:nth-child(1){
        color: #023A63;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
   }
   span:nth-child(2){
        font-style: italic;
        font-size: 14px;
        line-height: 15px;
        color: #41444B;
}
}
.moreInfo-content{
    margin-bottom: 25px;
    font-style: normal;
    font-weight: normal;
    color: #41444B;
    font-size: 14px;
    line-height: 15px;
    p{
        margin-bottom: 5px;
        
    }
    textarea{
        width: 100%;
        height: 20vh;
        font-style: italic;
        padding: 10px 0px 0px 10px;
    }
    
}

.new-rate-container{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    p:nth-child(1){
        color: #41444B;
        padding: 10px 0px 0px 0px;
    }
    
    span{
        font-style: italic;
        font-size: 14px;
        line-height: 15px;
        color: #41444B;
    }
    // p:nth-child(2){
        
    // }
    img{
        margin-right: 3px;
    }
    input{
        background: #FFFFFF;
        border: 1px solid #D4D7DD;
        box-sizing: border-box;
        margin-right: 10px;
        height: 6vh;
        // margin-bottom: 10px;
    }
}

.price-message{
        color: #767676;
        font-size: 14px;
        line-height: 19px;
        margin-top: 5px;
}

.quote-attachment{
        background: #FFFFFF;
        border: 1px dashed #D4D7DD;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        height: 15vh;
        align-items: center;
        margin-bottom: 25px;
        font-style: italic;
}
