.textarea{
    @media screen and (max-width:400px) {
        width:94%
    }
    @media screen and (min-width:401px) and (max-width:600px) {
        width:95%
    }
}
.details,.newProject{
    color:#00215B;
    font-weight: 600;
}
.address{
    @media screen and (max-width:400px) {
        .adLine1{
            width: 80%;
        }
        .adLine2{
            width: 80%;
        }
    }
  
}
// .phone{
//     @media screen and (max-width:400px) {
//         width: 80%;
//     }
// }
@media screen and (min-width:260px) and (max-width:767px){
    .customer-project{
           input{
                width: 88% !important;
           }
           textarea{
            width: 88% !important;
           } 
    }
}
.builderType{
    @media screen and (max-width:400px) {
        width: 80%;
    }
}
.landsize{
    @media screen and (max-width:400px) {
        width: 80%;
    }
}
.toggletext{
        position:absolute;
        margin-top: 12px;
    
}
.Projects p {
    margin-top: 7px;
}
.hire{
    background-color:#033B64 !important;
}
button{
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    // text-align: right;
    color: #FFFFFF;
    background: #033B64;
    border: none;
}