.BuilderProfile {
    width: 100%;
    background-image: url('../../../../assets/images/Bgimage.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    .overlay {
        background-color: #033b64;
        opacity: 0.7;
        padding: 150px 0px;
        color: #ffffff;
        p {
            text-align: justify;
        }
    }
    h1 {
        font-size: 55px;
    }
    .knowmore {
        padding: 15px 25px;
        background-color: #fab005;
        border: 0px;
        color: #ffffff;
        font-size: 13px;
    }
    p {
        font-size: 15px;
    }
}

.Projects {
    .container.py-5.section{
        padding: 0!important;
    }
    .btn-text {

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        text-align: right;
        text-transform: none;
        color: #ffffff;
        @media (max-width:340px) {
            font-size: 11px;
        }
    }
.text-center{
    @media (max-width:340px) {
        font-size: 11px;
    }
}
// .projectflex{
//     @media (max-width:340px) {
       
//     }
// }

    h3 {
        color: #033b64;
    }
    h5 {
        color: #033b64;
    }

    p {
        color: #41444b;
        overflow-wrap: break-word;
    }

    .compyLogo {
        box-shadow: 0px 0px 5px #ddd;
    }

    .slideBtns {
        position: relative;
        top: -50%;
        width: 100%;
        button {
            padding: 15px;
            background-color: #fab005;
            border: 0px;
        }
    }

    .pdfblock {
        border: 1px dashed #d4d7dd;
        box-sizing: border-box;
        padding: 40px 40px;
        margin: 20px 0px;
    }
    .contact {
        padding: 15px;
        background-color: #ffa20d;
        border: 0px;
        color: #ffffff;
    }
    .hire {
        padding: 15px;
        background-color: #00215b;
        border: 0px;
        color: #ffffff;
    }

    ul {
        margin: 0px;
        padding: 0px;
        li {
            // border-left: 5px solid #fab005;
            margin-top: 40px;
            box-shadow: 0px 0px 5px #ddd;
            img {
                width: 100%;
                height: 100%;
            }

            h4 {
                font-weight: 700;
            }

            a {
                color: #033b64;
                text-decoration: none;
            }
        }
    }
}

@media screen and (max-width:280px) {
   .col-md-2{
        span{
    margin-left: 30px !important;
}
}
}