.Reset-password-container{
    input{
        width: 27%;
        font-style: italic;
        font-size: 15px;
        margin-bottom: 10px;
    }
    p{
        margin-bottom: 10px;
    }
    
}